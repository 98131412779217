import React from "react"
import { silentAuth } from "./src/utils/auth"


class SessionCheck extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
    }
  }

  handleCheckSession = () => {
    this.setState({ loading: false })
  }

  componentDidMount() {
    silentAuth(this.handleCheckSession)
  }

  render() {
    return (
      this.state.loading === false && (
        <React.Fragment>{this.props.children}</React.Fragment>
      )
    )
  }
}

export const wrapRootElement = ({ element }) => {
  return <SessionCheck>{element}</SessionCheck>
}

//For mobile navigation
const navSlide = () => {
  const burger = document.querySelector('.burger');
  const nav = document.querySelector('.navLinks');
  //const head = document.querySelector('.navLogo');
  const navLinks = document.querySelectorAll('.navLinks li');
  
  if(burger == null){
      return;
  }
  burger.addEventListener('click', () => {
  nav.classList.toggle('navActive');
  //head.classList.toggle('logoActive');
  burger.classList.toggle('burgerActive');
  navLinks.forEach((link, index) => {
    if (link.style.animation){
      link.style.animation = '';
    } else {
        link.style.animation = `navLinkFade 0.3s ease forwards ${index / 7 + 0.3}s`;
      }
    });
  
    burger.classList.toggle('toggle');
  
    });
}
  
 
export const onRouteUpdate = () => {
  navSlide();
};